/* App.css */

.comparison-table {
  font-family: "Poppins", sans-serif;
  color: #333;
  text-align: center;
  /* border-collapse: collapse; */
  background-color: transparent !important;
  /* table-layout: fixed;  */
  width: 100%; /* Set to full width or any specific width */
}

.comparison-table tbody {
  background-color: transparent;
}

/* Table header styling */
.comparison-table th {
  /* color: #dcf552; Change header text color for visibility */
  font-weight: bold;
  font-size: 12px;
  padding: 10px;
  border: 3px solid #dcf552;
  border-top: none;
  height: 60px; /* Set a consistent height for headers */
}

.comparison-table th:first-child {
  border-left: none; /* Remove the left border */
  border-top: none; /* Remove the top border */
}

.comparison-table th:last-child {
  border-right: none; /* Remove the right border */
}

.comparison-table td {
  font-weight: bold;
  padding: 2px;
  /* text-align: left; */
  border: 3px solid #dcf552;
  width: 200px; /* Set a maximum width for table cells */
  word-wrap: break-word; /* Force the content to break and fit within the max-width */
  text-align: center; /* Center the content horizontally */
  vertical-align: middle; /* Center the content vertically */
  height: 100px;
}

.comparison-table th:first-child,
.comparison-table td:first-child {
  width: auto; /* Allow the first column to be auto-sized */
  text-align: start;

}

/* Ensure first and last td have no left/right borders */
.comparison-table td:first-child {
  border-left: none;
  /* background-color: transparent !important; */
}

.comparison-table td:last-child {
  border-right: none;
  /* background-color: transparent !important; */
}

/* Header content styling */
.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Icon styling */
.header-icon {
  margin-bottom: 5px;
  font-size: 24px; /* Adjust based on the icon size */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .comparison-table th,
  .comparison-table td {
    padding: 10px;
    font-size: 14px;
  }

  .header-icon {
    font-size: 20px; /* Adjust for smaller screens */
  }
}

.comparison-table tr:last-child td {
  border-bottom: none; /* Remove bottom border for last row cells */
  /* background-color: transparent !important; */
}

.th-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.th-content img {
  margin-bottom: 5px;
}

.th-content span {
  text-align: center;
  font-size: 14px; /* Adjust size if needed */
}
