/* App.css */
.nav-days {
    background-color: #F3A7BB; /* Adjust background color */
    border-radius: 20px; /* Adjust border radius */
    padding: 2px; /* Adjust padding */
  }
  
  .lun-link {
    position: relative; /* Allows for positioning pseudo-elements */
    padding-left: 4px; /* Adds space for the circle */
  }
  
  .lun-link::before {
    content: ''; /* Creates an empty pseudo-element */
    position: absolute; /* Positions the pseudo-element */
    top: 50%; /* Center vertically */
    left: 0; /* Aligns to the left */
    width: 100%; /* Extends to the end of the "LUN" link */
    height: 100%; /* Matches the height of the link */
    background-color: '#FF2C64'; /* Light background for the circle effect */
    border-radius: 50%; /* Makes it a circle */
    transform: translateY(-50%); /* Centers the circle vertically */
    z-index: -1; /* Places the circle behind the text */
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .lun-link {
      font-size: 0.8rem;
      padding-left: 4px;
    }
  
    .lun-link::before {
      background-color: '#FF2C64';
    }
  }
  
  @media (max-width: 576px) {
    .lun-link {
      font-size: 0.7rem;
      padding-left: 4px;
    }
  
    .lun-link::before {
      background-color: '#FF2C64';
    }
  }
  