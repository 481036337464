.custom-card {
  position: relative;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px; /* Ensure the card has a border-radius */
  overflow: hidden; /* This will make sure child elements like images are clipped */
}

.custom-card-img {
  object-fit: cover;
  border-radius: 20px;
}

.text-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: white;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 20px;
  font-weight: lighter;
}

.card-name {
  font-size: 1.25rem;
  font-weight: bolder;
}

.bottom-text {
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 1.5rem;
  font-weight: bold;
}
