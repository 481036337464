/* src/assets/fonts/fonts.css */
@font-face {
    font-family: 'National2Black';
    src: url('./National\ 2\ Black.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'National2Bold';
    src: url('./National\ 2\ Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'National2Light';
    src: url('./National\ 2\ Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'National2Medium';
    src: url('./National\ 2\ Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'National2Regular';
    src: url('./National\ 2\ Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'National2Thin';
    src: url('./National\ 2\ Thin.otf') format('opentype');
  }

  @font-face {
    font-family: 'National2XBold';
    src: url('./National\ 2\ XBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'National2XLight';
    src: url('./National\ 2\ XLight.otf') format('opentype');
  }
  
  /* Add more fonts as needed */
  